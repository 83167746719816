import generateTransition from "utils/generateTransition";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

export const fieldStyles = {
  basicField: {
    width: "100%",
  },
  inputLabel: {
    ...gStyles.avalonBold,
    color: colours.black,
    margin: "0 0 .25rem",
    fontSize: "15px",
    letterSpacing: "0.04px",
  },
  inputField: {
    backgroundColor: "#f2f2f2",
    border: "1px solid #f2f2f2",
    padding: "17px 20px 16px",
    fontSize: "1rem",

    "::placeholder": {
      ...gStyles.fontRegular,
      letterSpacing: "0.05px",
      color: "rgba(0, 0, 0, 0.55)",
    },
    ":hover": {
      boxShadow: "none",
      backgroundColor: "#e8e8e8",
    },
    ":focus": {
      boxShadow: "none",
      backgroundColor: "#e8e8e8",
    },
  },
  customSelectField: {
    width: "100%",
    padding: 0,
  },
  label: {
    minHeight: "1rem",
  },
  searchIcon: {
    color: "#6e6d7b",
  },
  selectArrow: {
    color: "#6e6d7b",
    fontSize: "1.25rem",
    paddingRight: "1rem",
    paddingTop: ".1rem",
  },
  inputRequired: {
    width: 5,
    height: 5,
    marginTop: ".4rem",

    [ScreenSizes.lgAndAbove]: {
      width: 4,
      height: 4,
    },
  },
  avaHolderFocused: {
    backgroundColor: "#e8e8e8",
  },
};

export const buttonStyles = {
  button: {
    width: "100%",
    height: "2.875rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 15,
    padding: "0 1.5rem",
  },
};

export const squareButtonStyles = {
  button: {
    ...buttonStyles.button,
    backgroundColor: "#f2f2f2",
    color: "#626d88",
    height: "100%",
    width: "100%",
    padding: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.1rem",
    ":hover": {
      backgroundColor: "#f2f2f2",
    },
    ":focus": {
      backgroundColor: "#e8e8e8",
    },
  },
  active: {},
};

export const socialImportStyles = {
  socialConnectActionButton: {
    marginLeft: ".5rem",

    ":first-child": {
      marginLeft: 0,
    },
  },
  socialImportButton: {
    display: "flex",
    width: "2.875rem",
    minWidth: "2.875rem",
    height: "2.875rem",
    fontSize: "1.1rem",
    position: "relative",
  },
  socialImportButtonStacked: {
    fontSize: "1rem",
    width: "100%",
    flex: 1,
    height: "2.25rem",
  },
  socialImportClose: {
    position: "absolute",
    top: -8,
    right: -8,
    width: 20,
    height: 20,
    fontSize: ".75rem",
    borderRadius: "50%",
    border: "2px solid #fff",
    backgroundColor: "#6e6d7b",
    color: colours.white,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  socialImportError: {
    position: "absolute",
    top: -8,
    left: -8,
    width: 20,
    height: 20,
    fontSize: ".875rem",
    color: colours.negative,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
};

export const importButtonStyles = {
  ...squareButtonStyles,

  button: {
    ...squareButtonStyles.button,
    fontSize: "inherit",
    color: "#6e6d7b",
    opacity: 1,
    transition: generateTransition({
      targets: ["opacity", "filter"],
      speed: "500ms",
    }),
  },
  disabled: {
    opacity: 0.6,
    filter: "grayscale(.6)",
  },
};

export const twitterButtonStyles = {
  ...importButtonStyles,
  active: {
    ...importButtonStyles.active,
    backgroundColor: colours.twitter,
    color: colours.white,
    ":focus": {
      backgroundColor: colours.twitterHover,
    },
    ":active": {
      backgroundColor: colours.twitterHover,
    },
    ":hover": {
      backgroundColor: colours.twitterHover,
    },
  },
};

export const facebookButtonStyles = {
  ...importButtonStyles,
  active: {
    ...importButtonStyles.active,
    backgroundColor: colours.facebook,
    color: colours.white,

    ":focus": {
      backgroundColor: "#7e9bc7",
    },
    ":active": {
      backgroundColor: "#7e9bc7",
    },
    ":hover": {
      backgroundColor: "#7390bf",
    },
  },
};

export const blueButtonStyles = {
  button: {
    ...buttonStyles,
  },
};

export default {
  description: {
    ...gStyles.fontRegular,
    color: colours.black,
    fontSize: "1rem",
    letterSpacing: "0.04px",
    lineHeight: "29px",
  },
  link: {
    color: colours.primary,
    cursor: "pointer",
    ":hover": {
      textDecoration: "underline",
    },
    ":focus": {
      textDecoration: "underline",
    },
  },
  footerText: {
    ...gStyles.fontBold,
    color: colours.black,
    fontSize: ".875rem",
    marginTop: "1.375rem",
    width: "100%",
    textAlign: "center",
  },
  semiBold: {
    ...gStyles.fontSemiBold,
  },
  fieldDetails: {
    ...gStyles.fontRegular,
    color: colours.black,
    letterSpacing: "0.03px",
    fontSize: ".875rem",
    margin: 0,
    lineHeight: "21px",
  },
  termPrivacyDescription: {
    ...gStyles.fontMedium,
    fontSize: ".875rem",
    color: colours.black,
    letterSpacing: "0.04px",
    lineHeight: "1.5rem",
  },

  form: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1.5rem",

    [ScreenSizes.lgAndAbove]: {
      marginTop: 0,
    },
  },
  formDescription: {
    ...gStyles.avalonBold,
    color: "#000",
    fontSize: "1.25rem",
    lineHeight: "1.875rem",
    textAlign: "center",
    marginBottom: "2rem",

    [ScreenSizes.lgAndAbove]: {
      textAlign: "left",
    },
  },
  formFooter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    [ScreenSizes.mdAndAbove]: {
      flexDirection: "row",
      justifyContent: "flex-end",
    },
  },
  formRow: {
    marginBottom: "1rem",
    width: "100%",
  },
  smallButton: {
    width: "6rem",
  },
  largeButton: {
    width: "12rem",
  },
};
